<template>
  <b-container class="dynamic-content">
    <a id="advantagesAnchor"></a>
    <b-row>
      <b-col cols="12" class="text-center">
        <h2 class="advantagesTitle">
          {{ $t("Buy.Content.WhatAreTheAdvanteges") }}
        </h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div>
          <b-row class="firstItem no-gutters shadow-sm rounded overflow-hidden">
            <b-col cols="12" md="6" class="bg-white">
              <div class="h-100 p-sm-4 pb-sm-4 pb-0 p-3 pr-lg-0">
                <b-img
                  class="mainImage rounded"
                  style="object-fit: cover"
                  :src="warrantyImage"
                />
              </div>
            </b-col>
            <div
              class="col p-sm-4 p-3 pb-1 contentBox bg-white d-flex flex-column justify-content-between"
            >
              <div>
                <h6 class="text-primary font-600">
                  {{ $t("Buy.Content.Warranty") }}
                </h6>
                <h3 class="mb-5 certified-text">
                  <!-- Certified Cars. -->
                  {{ $t("Buy.Content.CertifiedCars1") }}
                  <span class="p-underline pb-2 text-nowrap"
                    >{{ $t("Buy.Content.CertifiedCars2") }}
                    <!-- You're covered! -->
                  </span>
                </h3>
                <ul class="coverd-list">
                  <li>
                    <p>
                      {{ $t("Buy.Content.PashRigorouslyInspect") }}
                      <!-- Pash rigorously inspect every vehicle and provide an
                      accurate report detailing the current condition and
                      history of the vehicle.<br /><br
                    /> -->
                    </p>
                  </li>
                  <li>
                    <p>
                      {{ $t("Buy.Content.ProtectedIfUndetected") }}
                      <!-- You are protected if undetected or unreported issues show
                      up when you take delivery of your vehicle.<br /><br
                    /> -->
                    </p>
                  </li>
                  <li>
                    <p>
                      {{ $t("Buy.Content.EveryVehicle") }}
                      <!-- Every vehicle comes with a minimum powertrain protection
                      of 30 days or 1500 km whichever comes first. The duration
                      of the protection is higher on some models.-->
                    </p>
                  </li>
                </ul>
              </div>
              <b-row class="m-3 mx-0 mt-lg-5 text-center">
                <b-col sm="12" md="6" class="p-md-1 px-1 pb-2">
                  <b-btn
                    block
                    variant="outline-primary"
                    class="btn-block radius-btn"
                    v-if="CNTD_Story"
                    v-b-modal.gridModal
                    @click="modalContent = $t('Buy.Content.Warranty')"
                  >
                    {{ $t("Buy.Content.LearnMore") }}
                  </b-btn>
                </b-col>
                <b-col sm="12" md="6" class="p-1">
                  <router-link
                    class="btn btn-primary btn-block radius-btn shop-all-cars"
                    to="search"
                  >
                    {{ $t("Buy.Content.ShopAllCars") }}
                  </router-link>
                </b-col>
              </b-row>
            </div>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <section class="wrapper first firstItem">
      <div class="first-box">
        <div
          class="p-sm-4 p-3 bg-white h-100 d-flex flex-column justify-content-between shadow-sm rounded"
        >
          <div class="text-left">
            <h6 class="text-primary font-600">
              {{ $t("Buy.Content.AllInPrices") }}
            </h6>
            <h3 class="text-dark font-wight-bold mb-4">
              {{ $t("Buy.Content.SimpleAndTransparent") }}
              <!-- Simple and Transparent -->
            </h3>
            <p>
              {{ $t("Buy.Content.PricesIncludeShippingCosts") }}
              <!-- Our prices include shipping costs and all other fees and charges
              to get the vehicle delivered in your country. You will never be
              charged extra fees after you submit a reservation. -->
            </p>
          </div>

          <div class="d-flex flex-column justify-content-between">
            <b-btn
              block
              variant="outline-primary"
              class="mt-4 radius-btn-sm"
              v-b-modal.gridModal
              @click="modalContent = $t('Buy.Content.AllInPrices')"
              >{{ $t("Buy.Content.LearnMore") }}</b-btn
            >
          </div>
        </div>
      </div>
      <div class="first-box">
        <div
          class="p-sm-4 p-3 bg-white h-100 d-flex flex-column justify-content-between shadow-sm rounded"
        >
          <div class="text-left">
            <h6 class="text-primary font-600">
              {{ $t("Buy.Content.Payment") }}
            </h6>
            <h3 class="text-dark font-wight-bold mb-4">
              {{ $t("Buy.Content.EasyPayment") }}
            </h3>
            <p>
              {{ $t("Buy.Content.PayInYourCountryInCurrency") }}
              <!-- You can pay in your country in the currency of your choice.
              Accepted payment methods include cash, checks, credit cards,
              mobile payments or electronic bank transfers. Payment plans
              available. -->
            </p>
          </div>

          <div class="d-flex flex-column justify-content-between">
            <b-btn
              block
              variant="outline-primary"
              class="mt-4 radius-btn-sm"
              v-b-modal.gridModal
              @click="modalContent = $t('Buy.Content.Payment')"
              >{{ $t("Buy.Content.LearnMore") }}</b-btn
            >
          </div>
        </div>
      </div>
      <div class="first-box">
        <div
          class="p-sm-4 p-3 bg-white h-100 d-flex flex-column justify-content-between shadow-sm rounded"
        >
          <div class="text-left">
            <h6 class="text-primary font-600">
              {{ $t("Buy.Content.CustomsClearance") }}
            </h6>
            <h3 class="text-dark font-wight-bold mb-4">
              {{ $t("Buy.Content.Optional") }}
              <!-- Optional. It's up to you! -->
            </h3>
            <p>
              {{ $t("Buy.Content.HandleCustomsClearance") }}
              <!-- We can handle customs clearance procedures and pay all applicable
              duties and taxes on your behalf. If interested, please add the
              customs clearance option when submitting or confirming your
              reservation. -->
            </p>
          </div>

          <div class="d-flex flex-column justify-content-between">
            <b-btn
              block
              variant="outline-primary"
              class="mt-4 radius-btn-sm"
              v-b-modal.gridModal
              @click="modalContent = $t('Buy.Content.CustomsClearance')"
              >{{ $t("Buy.Content.LearnMore") }}</b-btn>
          </div>
        </div>
      </div>
    </section>

    <section class="wrapper second">
      <div class="second-box">
        <div
          class="p-sm-4 p-3 bg-white h-100 d-flex flex-column justify-content-between shadow-sm rounded"
        >
          <div class="text-left">
            <h6 class="text-primary font-600">
              {{ $t("Buy.Content.Delivery") }}
            </h6>
            <h3 class="text-dark font-wight-bold mb-4">
              {{ $t("Buy.Content.Guaranteed") }}
              <!-- 100% Guaranteed -->
            </h3>
            <p>
              {{ $t("Buy.Content.WarringAboutShipping") }}
              <!-- Don&rsquo;t worry about shipping or logistics. We take care of
              everything and deliver the car to you in your country. We
              guarantee you will receive your vehicle by the delivery date
              that&rsquo;ll be indicated on your sales contract. -->
            </p>
          </div>

          <div class="d-flex flex-column justify-content-between">
            <b-img
              rounded
              class="w-100 mt-4 radius-btn-sm"
              style="object-fit: cover; height: 300px"
              :src="deliveryImage"
            />
            <b-btn
              block
              variant="outline-primary"
              class="mt-4 radius-btn-sm"
              v-b-modal.gridModal
              @click="modalContent = $t('Buy.Content.Delivery')"
              >{{ $t("Buy.Content.LearnMore") }}</b-btn
            >
          </div>
        </div>
      </div>
      <div class="second-box">
        <div
          class="p-sm-4 p-3 bg-white h-100 d-flex flex-column justify-content-between shadow-sm rounded"
        >
          <div class="text-left">
            <h6 class="text-primary font-600">
              {{ $t("Buy.Content.CustomerServices") }}
              <!-- CUSTOMER SERVICES -->
            </h6>
            <h3 class="text-dark font-wight-bold mb-4">
              {{ $t("Buy.Content.SalesRepresentataives") }}
              <!-- Sales representatives near you. -->
            </h3>
            <p>
              {{ $t("Buy.Content.CustomerServicesDescription") }}
              <!-- You can meet them in-person or contact them by phone or email and
              they&rsquo;ll be happy to help or answer any question you may
              have. -->
            </p>
          </div>
          <div class="d-flex flex-column justify-content-between">
            <b-img
              rounded
              class="w-100 mt-4"
              style="object-fit: cover; height: 300px"
              :src="representativesImage"
            />
            <b-btn
              block
              variant="outline-primary"
              class="mt-4 radius-btn-sm"
              @click="$router.push({ path: 'contact-representatives' })"
            >
              {{ $t("Buy.Content.LearnMore") }}
            </b-btn>
          </div>
        </div>
      </div>
    </section>

    <b-modal hide-footer id="gridModal" :title="modalContent">
      <div class="p-4">
        <div>{{ $t("global.label.comingsoon") }}</div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>

export default {
  name: "TheContentSwitcher",
  data() {
    return {
      modalContent: null,
      story1: "<h1>What are the advantages of buying with Pash?<br /><br /></h1>",
      CNTD_Story:
        '<p><svg width="22px" height="32px" viewbox="0 0 22 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>award@2x</title> <desc>Created with Sketch.</desc> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Pash---Buy" transform="translate(-288.000000, -1902.000000)" stroke="#2C3139" stroke-width="2"> <g id="award" transform="translate(289.000000, 1903.000000)"> <polyline id="Shape" points="17 17.14 17 29 10 26 3 29 3 17.14"></polyline> <circle id="Oval" stroke-linecap="square" cx="10" cy="10" r="10"></circle> </g> </g> </g> </svg></p> <h6>Warranty</h6>',
      warrantyImage: require("@/assets/img/mechanic.jpg"),
      deliveryImage: require("@/assets/img/guaranteed_1x.jpg"),
      representativesImage: require("@/assets/img/representatives_near_you_1x.jpg"),
      currentTitle: ""
    };
  },
  components: {},
  computed: {
    path() {
      //returns the url after pash.com
      return this.$route.path;
    },
  },
  props: {
    text: {
      type: Array,
    },
  },
  methods: {
    showText(key) {
      let t;
      if (this.text) {
        this.text.forEach((element) => {
          if (element.Key === key) {
            t = element.Value;
          }
        });
      }
      return t;
    },
  },
  created() {},
};
</script>

<style lang="scss">
.mainImage {
  height: 100%;
  width: 100%;
}
.contentBox {
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: baseline;
      color: #2277ef !important;
      margin-bottom: 1rem;
      p {
        color: #212529;
        margin: 0;
        line-height: 22px;
      }
      &:before {
        content: "●";
        font-size: 20px;
        margin-right: 17px;
        color: #006ac3;
      }
    }
  }
}
.advantagesTitle {
  margin-top: 68px;
  margin-bottom: 68px;
}
.radius-btn {
  height: 56px;
  border-radius: 40px !important;
}
.coverd-list {
  margin-left: -20px;
}
h3 {
  font-size: 24px !important;
  line-height: 32px !important;
}
.radius-btn-sm {
  border-radius: 30px !important;
  height: 42px;
  text-transform: capitalize;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  row-gap: 24px;
}
.wrapper.first {
  margin-top: 24px;
}
.wrapper.second {
  margin-top: 24px;
}
.first-box {
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
}
.second-box {
  width: 50%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
}
.first-box .rounded,
.second-box .rounded,
.contentBox {
  border-radius: 8px !important;
}
@media (max-width: 991px) {
  .mainImage {
    height: 380px;
  }
  .wrapper {
    row-gap: 16px;
  }
  .wrapper.first h3 {
    font-size: 20px !important;
    line-height: 30px !important;
  }
}
@media (max-width: 991px) {
  .advantagesTitle {
    font-size: 34px;
    line-height: 48px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    flex-direction: column;
  }
  .first-box,
  .second-box {
    width: 100% !important;
  }
  .radius-btn {
    height: 42px;
  }
}

@media (max-width: 575px) {
  .advantagesTitle {
    margin-top: 30px;
    margin-bottom: 38px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .certified-text {
    font-size: 20px !important;
    line-height: 30px !important;
    font-family: Inter !important;
  }
}
a.shop-all-cars {
  color: white !important;
}
.btn-outline-primary {
  transition: 0.3s;
  &:hover {
    background: #00559d !important;
    color: #fff !important;
  }
}
</style>
